import React from 'react';
import './about.css';
import CTA from './CTA';
import {AiOutlineUser} from 'react-icons/ai';

const About = () => {
    return (
        <>
        <section id='about'>
                <h2>About Carl H. Lombardi</h2>
                <h3>My Development Skills</h3>

                <div className='container about_container'>
                <div className='about_content'>
                    <div className='about_cards'>
                        <article className='about_card'>
                            <AiOutlineUser className='about_icon'/>
                            <h5>Front End Development</h5>
                            <p>HTML/CSS/Bootstrap</p>
                            <p>Javascript</p>
                            <p>React</p>
                        </article>
                        <article className='about_card'>
                            <AiOutlineUser className='about_icon'/>
                            <h5>Back End Development</h5>
                            <p>PHP</p>
                            <p>SQL Databases</p>
                            <p>Non SQL Databases</p>
                        </article>
                    </div>
                </div>
                </div>

                <h3>My Creative Skills</h3>

                <div className='container about_container'>
                <div className='about_content'>
                    <div className='about_cards'>
                        <article className='about_card'>
                            <AiOutlineUser className='about_icon'/>
                            <h5>Online Marketing</h5>
                            <p>Curated Branded E-mails</p>
                            <p>Social Media Posts</p>
                            <p>Brand Oriented Copy</p>
                        </article>
                        <article className='about_card'>
                            <AiOutlineUser className='about_icon'/>
                            <h5>Graphics/Branding</h5>
                            <p>Business Cards</p>
                            <p>T-Shirts</p>
                            <p>Digital Tag Design for Retail</p>
                        </article>
                    </div>
                </div>
                </div>
                <CTA />
        </section>
        </>
    )
}

export default About;