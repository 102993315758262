import React from 'react';
import './portfolio.css';
import Rossi from '../../assets/images/RossiCaseStudy.png';
import Valentine from '../../assets/images/VMFGCaseStudy.png';
import Hoboken from '../../assets/images/HRFCaseStudy.png';
import Gamesack from '../../assets/images/GameSackCaseStudy.png';


const Portfolio = () => {
    return (
        <>
        <section id='portfolio'>
                <h2>Selected Works</h2>

                <div className='container portfolio_container'>
                <div className='portfolio_content'>
                    <div className='portfolio_cards'>
                        <article className='portfolio_card'>
                            <a className='rossi' href ="https://www.rossipaint.com" target="_blank" rel='noreferrer'>
                            <img src={Rossi} alt='rossi' />
                            </a>
                        </article>
                        <article className='portfolio_card'>
                        <a className='valentine' href ="https://www.valentinemfg.com" target="_blank" rel='noreferrer'>
                            <img src={Valentine} alt='valentine' />
                            </a>
                        </article>
                        <article className='portfolio_card'>
                        <a className='hoboken' href ="https://www.hobokenrelieffund.com" target="_blank" rel='noreferrer'>
                            <img src={Hoboken} alt='hoboken' />
                            </a>
                        </article>
                        <article className='portfolio_card'>
                        <a className='gamesack' href ="https://www.gamesack.net" target="_blank" rel='noreferrer'>
                            <img src={Gamesack} alt='gamesack' />
                            </a>
                        </article>
                    </div>
                </div>
                </div>
        </section>
        </>
    )
}

export default Portfolio;