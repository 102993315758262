import React from 'react';

const CTA = () => {
    return (
        <div className='CTA'>
            <button type='submit' className='btn btn-primary'>Send E-mail</button>
        </div>
    )
}

export default CTA;