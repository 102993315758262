import React, {useRef} from 'react';
import {MdOutlineEmail} from 'react-icons/md';
import CTA from './CTA';
import emailjs from 'emailjs-com';
import './contact.css';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_1lgc1jh', 'template_fpchtrk', form.current, 'aXHszEyhArRgZJsLL')
       
      e.target.reset()
    };
    return (
        <section id='contact'>
            <h2>Contact Me</h2>

            <div className='container contact_container'>
                <div className='contact_options'>
                    <article className='contact_option'>
                        <MdOutlineEmail className='contact_option_icon'/>
                        <h4>E-mail</h4>
                        <h5>carl@carlhlombardi.com</h5>
                        <a href = "mailto:carl@carlhlombardi.com" target="_blank" rel='noreferrer'>Send E-mail</a>
                    </article>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <input type= "text" name='name' placeholder='Your Full Name' required />
                    <input type= "email" name='email' placeholder='Your E-mail' required />
                    <textarea name= "message" rows='7' placeholder='Your Message' required></textarea>
                    <CTA />
                </form>
            </div>
        </section>
    )
}

export default Contact;