import React from 'react';
import './header.css';
import CTA from './CTA';
import me from '../../assets/images/carl.webp';
import HeaderSocial from './HeaderSocial';

const Header = () => {
    return (
        <header>
            <div className = "container header_container">
                <h5>Hello I'm</h5>
                <h1>Carl H. Lombardi</h1>
                <h5 className= "text-light">Creative Director and Front End Developer</h5>
                <CTA />
                <HeaderSocial/>

                <div className='me'>
                    <img src={me} alt='me' />
                </div>

                <a href="#contact" className='scroll_down'>Scroll Down</a>
            </div>
        </header>
    )
}

export default Header;