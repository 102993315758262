import React from 'react';

const CTA = () => {
    return (
        <div className='CTA'>
            <a href ="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
    )
}

export default CTA;