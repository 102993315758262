import React from 'react';
import Resume from '../../assets/documents/Resume.pdf'; 

const CTA = () => {
    return (
        <div className='CTA'>
            <a href ={Resume} download className='btn'>My Resume</a>
            <a href ="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
    )
}

export default CTA;