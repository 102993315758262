import React from 'react';
import {BsLinkedin} from 'react-icons/bs';

const HeaderSocial = () => {
    return (
        <div className='header_social'>
            <a href = 'https://www.linkedin.com/in/carlhlombardi/' target= "_blank" rel= "noreferrer"><BsLinkedin/></a>
        </div>
    )
}

export default HeaderSocial;