import React from 'react';
import logo from '../../assets/images/logo.png';
import {BsLinkedin} from 'react-icons/bs';
import './footer.css';

const Footer = () => {
    return (
        <footer>
            <a href='/'><img className='footer_logo' src={logo} alt='logo' /></a>

            <ul className='permalinks'>
                <li><a href = '/'>Home</a></li>
                <li><a href = '#about'>About</a></li>
                <li><a href = '#portfolio'>Portfolio</a></li>
                <li><a href = '#contact'>Contact</a></li>
            </ul>
                
            <div className='footer_social'>
            <a href = 'https://www.linkedin.com/in/carlhlombardi/' target= "_blank" rel= "noreferrer"><BsLinkedin/></a>
            </div>

            <div className='footer_copyright'>
                <p>Copyright Carl H. Lombardi - 2022. </p>
                <br />
                <p>All Rights Reserved. </p>
            </div>
        
        </footer>
    )
}

export default Footer;